import { Route } from '@angular/router';
export const routes: Route[] = [
  {
    path: 'app/:ads/:id',
    loadComponent: () =>
      import('../app/share-app/share-app.component').then(
        (mod) => mod.ShareAppComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'app',
    loadComponent: () =>
      import('../app/share-app/share-app.component').then(
        (mod) => mod.ShareAppComponent
      ),
    pathMatch: 'full',
  },
  {
    path: 'user/delete-account',
    pathMatch: 'full',
    loadComponent: () =>
      import('./auth/pages/auth-frame/auth-frame.component').then(
        (mod) => mod.AuthFrameComponent
      ),
  },
  { path: '**', redirectTo: 'app' },
];
